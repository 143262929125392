<template>
  <CommonBaseModal :config="config" @close="onHide()">
    <template #content>
      <div class="modal-email">
        <div class="modal-email__header">
          <h2>
            {{ VERIFY_EMAIL.TITLE_FORM }}
          </h2>
          <div class="close-btn" @click="onHide" />
        </div>
        <CommonBaseLoading v-if="isLoading" />
        <div v-else class="modal-email__body">
          <CommonFormConfirmEmail :text="textVerify" :icon="iconVerify" />
          <div class="modal-email__action">
            <CommonBaseButton
              v-if="isError"
              class="base-button--btn-primary"
              @click="onHide()"
            >
              {{ VERIFY_EMAIL.RESEND }}
            </CommonBaseButton>
            <CommonBaseButton v-else class="base-button--btn-primary" @click="backHome()">
              {{ VERIFY_EMAIL.GO_HOME }}
            </CommonBaseButton>
          </div>
        </div>
      </div>
    </template>
  </CommonBaseModal>
</template>
<script setup lang="ts">
import CommonBaseModal from '~/components/common/base-modal.vue'
import CommonBaseButton from '~/components/common/base-button.vue'
import CommonBaseLoading from '~/components/common/base-loading.vue'
import CommonFormConfirmEmail from '~/components/common/form/confirm-email/index.vue'
import { useVerifyEmail } from '~/composables/account/useVerifyEmail'
import { PAGE_URLS } from '~/config/page-url'
import { MODAL } from '~/constants/modal'
import { VERIFY_EMAIL } from '~/constants/account/profile/profile'

import useModal from '~/composables/useModal'

import { ModalConfig } from '~/types/modal.type'

const { closeModal } = useModal()
const config: ModalConfig = {
  id: MODAL.VERIFY_EMAIL,
  centered: true,
  hideClose: true,
  fullscreen: false,
  iconClose: 'icon-close-circle-fill',
  textBack: 'Back',
  noCloseOnBackdrop: true
}

const { textVerify, iconVerify, checkVerifyEmail, isError, isLoading } = useVerifyEmail()
const router = useRouter()
const route = useRoute()
const onHide = () => {
  closeModal(config.id)
  navigateTo({ query: { token: undefined } })
}

const backHome = () => {
  closeModal(config.id)
  router.push({ path: PAGE_URLS.HOME })
}

onMounted(() => {
  checkOpenPopup()
})

const checkOpenPopup = async () => {
  if (route.query.token) {
    await checkVerifyEmail()
  }
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/modals/confirm-email/index.scss" />
